<template>
  <div class="home">
    <Contact v-for="contact in contacts" :key="contact.name" :contact="contact"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Contact from '@/components/Contact.vue'

export default {
  name: 'HomeView',
  components: {
    Contact,
  },
  data () {
    return {
      contacts: [
        // {
        //   id: 1,
        //   sections: [
        //     {
        //       type: 'name',
        //       label: 'Name',
        //       text: 'John Doe',
        //     },
        //     {
        //       type: 'email',
        //       label: 'Email',
        //       text: 'epassos@blatstudio.com',
        //     },
        //   ]
        // },
      ]
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home {
  text-align: center;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  img {
    width: 200px;
  }
}
</style>
