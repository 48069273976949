<template>
  <div class="contactSection">
    <div class="icon">
      <svg viewBox="0 0 24 24">
        <use :xlink:href="`${icons}#${icon}`"></use>
      </svg>
    </div>
    <div class="label" v-text="section.label"></div>
    <div class="text">
      <a :href="`mailto:${section.text}`" v-if="section.type === 'email'">{{ section.text }}</a>
      <a :href="`tel:${section.text}`" v-else-if="section.type === 'phone'">{{ section.text }}</a>
      <a :href="section.text" target="_blank" v-else-if="section.type === 'website'">{{ section.text }}</a>
      <span v-else>{{ section.text }}</span>
    </div>
  </div>
</template>

<script>
import icons from '@/assets/icons.svg'

export default {
  name: 'ContactSection',
  props: {
    section: Object
  },
  data () {
    return {
      icons,
    }
  },
  methods: {
  },
  computed: {
    icon() {
      switch (this.section.type) {
        case 'email':
          return 'mail'
        case 'phone':
          return 'phone'
        case 'address':
          return 'home'
        case 'website':
          return 'web'
        default:
          return 'person'
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.contactSection {
  display: flex;
  align-items: center;
  margin: 0 auto 1rem;
  .icon {
    width: 2rem;
    height: 2rem;
    margin: 0 1rem 0 0;
    svg {
      width: 100%;
      height: 100%;
      fill: #ccc;
    }
  }
  .label {
    font-weight: 600;
    color: #ccc;
    margin: 0 1rem 0 0;
  }
  .text {
    &, a {
      font-size: 1rem;
      font-weight: 400;
      margin: 0 0 0 1rem;
      text-decoration: none;
      color: #ccc;
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
