<template>
  <router-view v-if="template" :class="template" :page="page" />
</template>

<script>
import firebaseApp from './firebaseApp.js'
import { getFirestore, getDoc, doc } from 'firebase/firestore'
const db = getFirestore(firebaseApp)

export default {
  props: {},
  data() {
    return {
      page: null,
      user: null,
    }
  },
  methods: {
    async getPage(id) {
      const docRef = doc(db, 'pages', id)
      const docSnap = await getDoc(docRef)
      if (!docSnap.exists()) {
        console.log('no such document')
        return
      }
      this.page = docSnap.data()
    },
    async getUser() {
      const docRef = doc(db, 'users', this.page.uid)
      const docSnap = await getDoc(docRef)
      if (!docSnap.exists()) {
        console.log('no such document')
        return
      }
      this.user = docSnap.data()
    },
  },
  computed: {
    template() {
      if (this.page == null) {
        return null
      }
      if (this.page.template != null) {
        return this.page.template
      }
      if (this.page.uid != null) {
        return this.userTemplate
      }
      return 'psd'
    },
    userTemplate() {
      if (this.user == null) return null
      if (this.user.template == null) return 'psd'
      return this.user.template
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.name == 'contact') {
          this.getPage(this.$route.params.id)
        }
      }
    },
    page: {
      immediate: true,
      handler() {
        if (this.page == null) return
        if (this.page.uid == null) return
        this.getUser()
      }
    }
  },
}
</script>

<style lang="scss">
// Dark mode

#app {
  text-align: center;
  color: #fff;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #3eaf7c;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
