<template>
  <div class="contact">
    <ContactSection v-for="section in sections" :key="section.text" :section="section"/>
  </div>
</template>

<script>
import ContactSection from '@/components/ContactSection.vue'

export default {
  name: 'ContactComponent',
  components: {
    ContactSection,
  },
  props: {
    contact: Object,
  },
  computed: {
    sections() {
      return this.contact.sections
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 1rem;
  padding: 1rem;
  background-color: #2e2e2e;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}
</style>
